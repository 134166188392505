import axios from "axios";
// import AuthContext, { authHook } from "../Providers/AuthProvider";
// import { useContext } from "react";
// import { refreshToekenRequestAPI } from ".";
// import { Navigate } from "react-router-dom";
// import { getTokens } from "../store/slices/userReducer";
let axiosInstance=null;
// Helper function to extract a specific cookie (e.g., token)
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
};
const MainApi=()=>{
  if(axiosInstance)
  {
    return axiosInstance
  }
  else{
axiosInstance= axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
  withCredentials: true,
});
return axiosInstance
  }
}

// Request interceptor to add token from cookies to the headers


export { MainApi };
