import {  createSlice } from "@reduxjs/toolkit";

const authTokenReducer=createSlice({
    name:'tokens',
    initialState:(localStorage.getItem('tokens') ?JSON.parse(localStorage.getItem('tokens')) : null),
    reducers:{
        updateTokens:(state,action)=>{
            console.log("action",action)
            if(action?.payload?.accessToken )
            {
                  state.accesstoken=action.payload.accessToken;
            }
            if(action?.payload?.refreshToken)
                {
                      state.refreshtoken=action.payload.refreshToken;
                }
                
        }

    }
})
export const {updateTokens}=authTokenReducer.actions;

export default authTokenReducer.reducer;