import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

const userSlice=createSlice({
    name:"User",
    initialState:{},
    reducers:{
        login:(state,action)=>{},
        logout:(state,action)=>{},
        signUp:(state,action)=>{},
        saveData:(state,action)=>{
           try {
             const data =jwtDecode(action.payload.accesstoken)
             return data;
           } catch (error) {
            console.log(error,"error");
           }
        },
        getTokens:(state,actions)=>{
            return state.tokens
        }
    }
})
export const{login,logout,signUp,saveData,getTokens}=userSlice.actions;
export default userSlice.reducer;