import React, { useCallback, useState } from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y,Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

// import productDetails from "../context/productDetails";
import AuthContext from "../../Providers/AuthProvider";
import YellowProductCard from "../YellowProductCard";
import { Icon } from "@iconify/react/dist/iconify.js";
import { getProductDataByCategoryAPI } from "../../api";
// import AuthContext from "../context/Auth";
const DOD = () => {
  const [swiper, setSwiper] = React.useState(null);
  // const product_data = React.useContext({});
  const [data, setdata] = React.useState([]);
  const [page,setPage]=React.useState(1);
  const [loading,setLoading]=useState(false)
  const [completed,setCompleted]=useState(false)
  const fetchdata = async (page) => {
 setLoading(true)
      try {
        const res=await getProductDataByCategoryAPI(`/api/products/category/biscuts?page=${page}`);
        if(res.data.data.length===0)
        {
          setCompleted(true)
        }
        let a=data
        a.push(...res.data.data)
        console.log(a);
       setdata([...a])
      } catch (error) {
        console.log("error while loading yelllow div products ",error);
      }
      finally{
        setLoading(false)
      }
    
  };
  // const handleSlideChangeTransitionEnd = () => {
  //   if (swiper) {
  //     swiper.autoplay.start();
  //   }
  // };
  React.useLayoutEffect(() => {
    fetchdata(page);
  }, [page]);

  const breakpoints = {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 3,
      spaceBetween: 5,
    },
    // when window width is >= 1024px
    1024: {
      slidesPerView: 6,
    },
  };
  const handleReachEnd = useCallback(()=>{
    if(data.length>0 && !loading)
      {
        console.log("reached end");
        setPage((prev)=>prev+1)
      }
  }, [data, loading]) 

  return (
   <>
    <div className=" w-[100%] md:min-h-[27rem]   flex justify-center items-center ">
      
      <div
        id="products"
        className="  w-[100%]   flex flex-col gap-4  justify-between items-start  "
      >
        <div className="w-[100%] h-[20%] flex justify-between px-2   md:px-8 items-center ">
          <div>
            <span className=" text-xl md:text-5xl  font-[900] drop-shadow-xl    ">
              DEALS OF THE DAY
            </span>
          </div>
          <div>
            <button className="bg-yellow-300 text-black   md:w-[11.6rem]  flex  rounded-xl shadow-lg md:text-2xl  items-center py-2 px-2 font-bold  ">
              <span>Explore More</span>
              <Icon icon="icon-park:right" />{" "}
            </button>
          </div>
        </div>
        <div className="w-[100%] h-[80%] flex justify-between gap-8 items-center  ">
      <Swiper onReachEnd={handleReachEnd}  navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}  modules={[Navigation, Pagination, Scrollbar, A11y,Autoplay]}  
  breakpoints={breakpoints}
    //   loop
      // slidesPerView={6}
       className='w-[100%]  flex justify-center items-center  ' aria-label="My Favorite Images">

{data.length>0 && data?.map((product , index)=>{
        return(
            <SwiperSlide>
               < YellowProductCard
              key={product.id}
              name={product.name}
              price={product.price}
              imgSrc={product.img_path}
              weight={product.weight}
              category={product.category}
              className="mx-auto "
              productId={product._id}
              cartquantity={0}
             /> 
            </SwiperSlide>
        )
    })
}
{!completed &&<SwiperSlide style={{}} className="d-flex items-center content-center  h-[20rem] w-full  " >
  <div className="h-[20rem] w-full  bg-red-400  d-flex items-center content-center text-center ">loading...</div>
</SwiperSlide>}
</Swiper>
</div>
      </div>
   
    
  </div>
   </>
    // <Swiper
    //   pagination={{ clickable: true }}
    //   rewind={false}
    //   slidesPerView={6}
    //   autoplay
    //   // onSlideChangeTransitionEnd={handleSlideChangeTransitionEnd}
    //   onSlideChange={() => console.log("slide change")}
    //   onSwiper={setSwiper}
    // >
    //   {[1,1,1,1,1,1,1,1,11,1,1,12,1,1,1,11]?.map((item, id) => {
    //     console.log('data',item)
    //     return (
    //       <SwiperSlide key={id}>
            
    //         {/* < YellowPoductCard
    //           key={id}
    //           name={item.name}
    //           price={item.price}
    //           imgSrc={item.img_path}
    //           weight={item.weight}
    //           category={item.category}
    //           className="mx-auto "
    //           productId={item.id}
    //           cartquantity={item.quantity}
    //         /> */}
    //           < YellowProductCard
    //           key={id}
    //           name={"item.name"}
    //           price={23}
    //           imgSrc={''}
    //           weight={"45"}
    //           category={"A"}
    //         //   className="mx-auto "
    //           productId={id}
    //           cartquantity={2}
    //         />
    //       </SwiperSlide>
    //     );
    //   })}
    // </Swiper>
  );
};

export default DOD;
