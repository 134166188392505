import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from './Root';
import SignUp from './Pages/SignUp';
import ForgetPassword from './Pages/ForgetPassoword';
import ForgetPassThroughEmail from './Pages/ForgetPassThroughEmail';
import Main from './Pages/Main';
import ProductDetails from './Pages/ProductsDetails';
import Home from './Pages/Home';
import OnSearch from './Pages/OnSearch/OnSearch';
import { Provider } from 'react-redux';
import store from './store';
import Cart from './Pages/Cart/Cart';

const root = ReactDOM.createRoot(document.getElementById('root'));
const router=createBrowserRouter([
  {
    path:'/',
    element:<Root/>,
    children:[
      {
        path:'/',
        element:<Main/>,
        children:[
          {
            path:'/',
            element:<Home/>
          },
          {
          path:'product/:id',
          element:<ProductDetails/>
          },
          {
          path:'cart',
          element:<Cart/>
          },
          {
          path:'onsearch/:id',
          element:<OnSearch/>
        }
      ]
      },{
path:'auth',
children:[
  {
    path:'signupuser',
    element:<SignUp/>
  },
  {
    path:'forgotpassword',
    element:<ForgetPassword/>
  },
  {
    path:'forgotpasswordemail/:a/:b',
    element:<ForgetPassThroughEmail/>
  }
]
      }
    ]

  }
])
root.render(
  <Provider  store={store} >
    <RouterProvider router={router}/>
   </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
