import React, { useCallback, useLayoutEffect, useState } from 'react';
import ProductCardTwo from '../ProductCardTwo';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y,Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
// import AuthContext from '../context/Auth';
// import { SkeletonCardTwo } from './Skeletons/Skeletons';
import AuthContext from '../../Providers/AuthProvider';
import { getProductDataByCategoryAPI } from '../../api';
import { SkeletonCardTwo } from '../../Skeletons/Skeletons';

const DealsDiv = ({category}) => {
  console.log("category",category)
  const [swiper, setSwiper] = React.useState(null); 
    const [data, setdata] = React.useState([]);
    const [page, setPage] = useState(1);
    const [loading,setLoading]=useState(false)
    const [completed,setCompleted]=useState(false)
    // const { authTokens} = React.useContext(AuthContext);
    const fetchdata = async (page) => {
      setLoading(true)
           try {
             const res=await getProductDataByCategoryAPI(`/api/products/category/${category}?page=${page}`);
             if(res.data.data.length===0)
             {
               setCompleted(true)
             }
             let a=data
             a.push(...res.data.data)
             console.log(a);
            setdata([...a])
           } catch (error) {
             console.log("error while loading normal div products ",error);
           }
           finally{
             setLoading(false)
           }
         
       };

useLayoutEffect(() => {

    fetchdata(page);
  }, [page]);
  const breakpoints = {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
     
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 3,
      spaceBetween: 30
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 3,
      spaceBetween: 40
    },
    // when window width is >= 1024px
    1024: {
      slidesPerView: 6,
  
    }
  };
  const handleReachEnd = useCallback(()=>{
    if(data.length>0 && !loading)
      {
        console.log("reached end");
        setPage((prev)=>prev+1)
      }
  }, [data, loading]) 
  return (
    <Swiper onReachEnd={handleReachEnd}  modules={[Navigation, Pagination, Scrollbar, A11y,Autoplay]} autoplay 
  breakpoints={breakpoints}
    //   loop
      slidesPerView={6}
>


{data?.length > 0 ? 
  data?.map((product, id) => {
    return (
      <SwiperSlide style={{display:'flex',justifyContent:'center'}} key={`${id + product.id}`}>
        <ProductCardTwo
          key={product.id}
          name={product.name}
          price={product.price}
          imgSrc={product.img_path}
          weight={product.weight}
          category={product.category}
          productId={product._id}
          className="mx-auto "

          cartquantity={product.quantity}
        />


      </SwiperSlide>
    );
  }) : 
  [1, 1, 1, 1, 1, 1, 1, 1].map((item, id) => {
    return (
      <SwiperSlide key={id} style={{display:'flex',justifyContent:'center'}}>
        <SkeletonCardTwo />
      </SwiperSlide>
    );
  })
}
{!completed &&<SwiperSlide style={{}} className="d-flex items-center content-center  h-[20rem] w-full  " >
<SkeletonCardTwo/>
</SwiperSlide>}
</Swiper>
  )
}
export default DealsDiv
