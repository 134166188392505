import React, { useState } from 'react'
import './Carousel.css'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y,Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

// import '@splidejs/react-splide/css'
// import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

// new Splide( '.splide' ).mount( { AutoScroll } );

const Carousel = () => {
    const images = ['/CarouselImages/image1.png' , '/CarouselImages/image2.png' , '/CarouselImages/image3.png' , '/CarouselImages/image4.png' , '/CarouselImages/image5.png']
    const linksa = ['1' , '2' ,'3' ,'4' ,'5']
    const [Index , setIndex] = useState(0)
    // useEffect(()=>{
    //     setTimeout(() => {
    //         return(
    //             Index < 4 ? setIndex(Index + 1) : setIndex(0)
    //         )
    //     }, 1000);
    // },[Index])
  return (
    <div className='CarouselWrapper  w-[100%] md:h-[28rem]  overflow-hidden relative   '>
       <Swiper  navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}  modules={[Navigation, Pagination, Scrollbar, A11y,Autoplay]} autoplay 
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      loop
       className='w-[100%]  flex justify-center items-center  ' aria-label="My Favorite Images">

{
    images.map((image , index)=>{
        return(
            <SwiperSlide>
                <a href={linksa[index]}>
                <img src={image}  alt="" className='object-cover CarouselImage'/>
                </a>
            </SwiperSlide>
        )
    })
}
</Swiper>
<button className="arrow-right absolute left-0 z-20 ">Prev</button>
<button className="arrow-left absolute right-0 z-20">next</button>
    </div>
  )
}

export default Carousel
