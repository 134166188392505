import React from 'react'
import Carousel from '../Components/MainCarousel'
import DOD from '../Components/DealsOfTheDayComponent'
import ProductsDiv from '../Components/ProductsDiv'

const Home = () => {
  return (
    <>
    <Carousel/>
      <DOD/>

      <ProductsDiv title={"Dairy,Bread&Eggs"} Category="dairy" />
      
     
      <ProductsDiv title={"biscuts"} Category="biscuts" />
   
   
      <ProductsDiv title={"namkeen"} Category="namkeen" />
   
   
      <ProductsDiv title={"Munchies"} Category="snacks" />
      </>
  )
}

export default Home