import { configureStore, Tuple } from "@reduxjs/toolkit";
import userSlice from './slices/userReducer';
import authTokensReducer from "./slices/authTokensReducer";
import globalLoader from "./slices/globalLoaderReducer"
import cartSlice from "./slices/cartRerducer";
import { thunk } from "redux-thunk";
const store=configureStore({
    reducer:{
        User: userSlice,
        authTokens:authTokensReducer,
        globalLoader,
        Cart:cartSlice


    },
      middleware: () => new Tuple(thunk) ,
})
export default store;