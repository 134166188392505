import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCartItemsAPI } from "../../api";
// interface cartSliceInterface{
//     CreatedAt:String
// brand:String
// category:String
// flavour:String
// id:Number
// img_path:String
// name:String 
// price:Number
// quantity:Number 
// stock:Number
// weight:String
// _id: String
// }
const FETCH_CART_PREFIX="fetchPartPrefix"
export const fetchCart= createAsyncThunk(FETCH_CART_PREFIX,async ()=>{
    const res=await getCartItemsAPI();
    return res.data
})
const cartSlice=createSlice({
    name:"Cart",
    initialState:{
        data: {},
        status: 'idle', 
    },
    reducers:{
        hydrateCart:(state,action)=>{
              action.payload.map(data=>{
                    
                    state.data[data.product._id]=data
                })
        },
        updateCartItem:(state,action)=>{
            console.log(action.payload,"product addition");
            state.data[action.payload._id]={...state[action.payload._id],product:{...action.payload},quantity:action.payload.quantity}
            
        },
        fetchCartItem:(state,action)=>{
                return state.action.payload._id??{}
        },
        deleteCartItem:(state,action)=>{
            delete state.action.payload._id
        }

    },
    extraReducers:(builder) => {
builder.addCase(fetchCart.pending,(state)=>{
    state.status="Pending"
}).addCase(fetchCart.fulfilled,(state,action)=>{
    state.status="success";
    console.log("cart action",action);
 action.payload.map(data=>{
                    
        state.data[data.product._id]=data
    })
})
    }
})
export const {hydrateCart,updateCartItem,fetchCartItem,deleteCartItem}=cartSlice.actions
export default cartSlice.reducer