import React from 'react'
import { Outlet } from 'react-router-dom'
import { AuthProvider } from './Providers/AuthProvider'

const Root = () => {
  return (
    <div>
      <AuthProvider>
      <Outlet/>
      </AuthProvider>
    </div>
  )
}

export default Root