import React from 'react'
import "./loader.css"
const Loader = () => {
  return (
<div className="loader">
       <p>loading</p>
       <div className="words">
           <span className="word">biscuits</span>
           <span className="word">Chocolates</span>
           <span className="word">chips</span>
           <span className="word">icecreams</span>
              <span className="word">Milk</span>
   
       </div>
</div>
  )
}

export default Loader