import axios from "axios";
import { MainApi as axiosInterceptor } from "./interceptor";
  const Get = async (url, config = {}) => {
    try {
      
      return axiosInterceptor().get(url, config);
  
    } catch (error) {
      console.log(error)
    }
  };
  
  // POST request
  const Post = async (url, data, config = {}) => {
    return axiosInterceptor().post(url, data, config);
  };
  
  // PATCH request
  const Patch = async (url, data, config = {}) => {
    return axiosInterceptor().patch(url, data, config);
  };
  const Delete = async (url,data,config={})=>{
    return axiosInterceptor().delete(url,data,config)
  }
  const Put=async (url,data,config={})=>{
    return axiosInterceptor().put(url,data,config)
  }
  const loginAPI=(url,data,config={})=>{
    return Post(url,data,config={});
  }
  const signUpAPI=(url,data,config={})=>{
    return Post(url,data,config={});
  }
  const getProductDataByCategoryAPI=(url,data,config={})=>{
    return Get(url,data,config={})
  }
  
  const getProductDataByIdAndSimilarProductsAPI=(url,data,config={})=>{
    return Get(url,data,config={})
  }
  const addToCartAPI=(data,config={})=>{
    return Post('/api/cart',data,config={})
  }
  const getCartItemsAPI=(data,config={})=>{
    return Get('/api/cart',data,config={})
  }
  const getProductReviewAPI=({productId},config={})=>{
    return Get(`/api/review/${productId} `,config={})
  }
  const addProductReviewAPI=({rating, comment,userId,productId},config={})=>{
    return Post(`/api/review `,{rating,comment,user:userId,product:productId},config={})
  }
  const editProductReviewAPI=({productId,userId,...rest},config={})=>{
    return Patch(`/api/review `,{user:userId,product:productId,...rest},config={})
  }
  const searchbarRequestedDataAPI=({productId},config={})=>{
    return Get(`api/searchbarRequestedData/${productId}`,config={})
  }
  const refreshToekenRequestAPI=(config={})=>{
    return Get(`${process.env.REACT_APP_BACKEND_URL}api/auth/refresh`)
  }
  const getValidateRefreshTokenAPI=(refreshToken,config={})=>{
    return Post(`${process.env.REACT_APP_BACKEND_URL}api/auth/getvalidateRefreshToken`,{refreshToken})
  }
export const searchBarDataAPI = (name,config={})=>{
  return Get(`${process.env.REACT_APP_BACKEND_URL}api/searchbarData/?name=${name}`,config={})
} 
  const api = {
    Get,
    Post,
    Patch,
    Delete,
    Put,
    loginAPI,
    signUpAPI,
  };
  
  // Default export for the API functions

  
  // Named export for axiosInterceptor() in case you need it elsewhere
  export { axiosInterceptor,   Get,
    Post,
    Patch,
    Delete,
    Put,
    loginAPI,refreshToekenRequestAPI,
    searchbarRequestedDataAPI,
    getProductReviewAPI,addProductReviewAPI,editProductReviewAPI,
    signUpAPI,getProductDataByCategoryAPI ,addToCartAPI,getProductDataByIdAndSimilarProductsAPI,getValidateRefreshTokenAPI,getCartItemsAPI};