import React, { useContext } from "react";
// import { Routes, Route } from "react-router-dom";
// import OnSearch from "./Components/OnSearch";
// import DetailsMain from "./Components/DetailsMain";
import Header from "../Components/Header";
import "../App.css";
// import "./loader.css";
// import mongoose, { connect } from "mongoose";
// import Footer from "./Components/Footer";
// import Cart from "./Components/Cart";
// import Home from "./Home";
import Footer from "../Components/Footer";
import { Outlet } from "react-router-dom";
import Carousel from "../Components/MainCarousel";
import DOD from "../Components/DealsOfTheDayComponent";
import DealsDiv from "../Components/DealsDiv";
import ProductsDiv from "../Components/ProductsDiv";

function App() {
  return (
    <div className="w-[100%] max-w-screen-2xl mx-auto flex flex-col justify-start items-center min-h-fit   ">
      <div className=" w-[100%]  md:min-h-[8rem] top-0 sticky  z-10 ">
        <Header />
      </div>

    <Outlet/>
        <div className=" w-[100%] min-h-[15rem]   ">
          <Footer/>
        </div>
    </div>
  );
}

export default App;

{
  /* <div className=" w-[100vw] h-[100vh] flex  justify-center items-center bg-black  " >
  <div className=" w-[13rem] h-[18rem] rounded-xl bg-white flex flex-col justify-start items-center " >
    <div className=" w-[100%] h-[50%]  flex justify-center items-center ">svsv</div>
    <div className=" w-[100%] h-[6%] flex justify-center items-start   "></div>
    <div className=" w-[100%] h-[15%]  flex justify-center items-center ">svsv</div>
    <div className=" w-[100%] h-[15%]  flex justify-center items-center ">svsv</div>
    <div className=" w-[100%] h-[20%]  flex justify-center items-center ">svsv</div>
  </div>
</div> */
}
