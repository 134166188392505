import { createContext, useState, useEffect, useCallback, useContext } from 'react';
import {jwtDecode} from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import "../Components/Loader/loader.css"
import Loader from '../Components/Loader'
import { getCartItemsAPI, getValidateRefreshTokenAPI, loginAPI, refreshToekenRequestAPI, signUpAPI } from '../api';
import { useDispatch, useSelector } from 'react-redux';
import { saveData } from '../store/slices/userReducer';
import { updateTokens } from '../store/slices/authTokensReducer';
import { MainApi } from '../api/interceptor';
import axios from "axios";
import store from '../store';
import { hideLoader, showLoader } from '../store/slices/globalLoaderReducer';
import { fetchCart, hydrateCart } from '../store/slices/cartRerducer';
const AuthContext = createContext()

export default AuthContext;


export const AuthProvider = ({children}) => {
    const dispatch=useDispatch()
    const {authTokens,globalLoader}=useSelector((state)=>state)

    // let [user, setUser] = useState(() => (localStorage.getItem('tokens') ?jwtDecode(JSON.parse(localStorage.getItem('tokens'))['accesstoken'])  : null))
    let [loading, setLoading] = useState(false)
   

useEffect(()=>{

  if(typeof window !== 'undefined')
  {
    const token=localStorage.getItem('tokens')
    if(token)
    {
      const data=JSON.parse(token);
      console.log(data,"sdsdsdsdd");
      dispatch(saveData({ accesstoken:data.accesstoken}))
    }

  }
},[dispatch])

    useEffect(() => {
      const axiosInterceptor = MainApi(); // Create Axios instance with interceptors
  
      const requestInterceptor = axiosInterceptor.interceptors.request.use(
        async (config) => {
          console.log('Request Interceptor: Request Config', config);
  
          let token;
          if (typeof window !== "undefined") {
            const tokens = localStorage.getItem('tokens');
            if (authTokens) {
              token = authTokens['accesstoken']; // Use the token from the Redux state
            }
            console.log("Token from localStorage:", token);
          }
  
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
          }
          config.headers['Content-Type'] = 'application/json';
          return config;
        },
        (error) => {
          console.error('Request Interceptor Error:', error);
          return Promise.reject(error);
        }
      );
  
      const responseInterceptor = axiosInterceptor.interceptors.response.use(
        (response) => {
          console.log('Response Interceptor: Response', response);
          return response;
        },
        async (error) => {
          console.log('Response Interceptor Error:', error.response ? error.response.status : 'No status');
          if (error.response && error.response.status === 403) {
            console.log('Calling the refresh token API...');
            const { data, status } = await refreshToekenRequestAPI(); // Call your refresh token API
            console.log("Called refresh token", data);
  
            if (status === 200 && data.accessToken) {
              const originalRequest = error.config;
              originalRequest.headers['Authorization'] = `Bearer ${data.accessToken}`;
  
              // Update the localStorage and Redux state
              let newToken;
              const tokens = localStorage.getItem('tokens');
              if (tokens) {
                newToken = JSON.parse(tokens);
              }
  
              localStorage.setItem('tokens', JSON.stringify({ ...newToken, accesstoken: data.accessToken }));
              dispatch(updateTokens({ accessToken: data.accessToken }));
     dispatch(saveData({ accessToken: data.accessToken }))

  
              // Retry the original request with the new token
              return axios(originalRequest);
            }
          }
          return Promise.reject(error);
        }
      );
  
      // Cleanup interceptors on component unmount
      return () => {
        axiosInterceptor.interceptors.request.eject(requestInterceptor);
        axiosInterceptor.interceptors.response.eject(responseInterceptor);
      };
    }, [authTokens, dispatch]);


// console.log("authTokens",authTokens); 
// if(authTokens)
// {
//     const axiosInterceptor=MainApi()

//     // axios data
//     axiosInterceptor.interceptors.request.use(
//         async (config) => {
//           console.log('Request Interceptor: Request Config', config); // Log request config
          
//           let token;
//           if (typeof window !== "undefined") {
//             const tokens = localStorage.getItem('tokens');
//             const test=store.getState().User;
//             console.log(test,"avc");
//             if (authTokens) {
//               token = authTokens['accesstoken'];
//             }
//             console.log("Token from localStorage:", token); // Log token
//           }
          
//           if (token) {
//             config.headers['Authorization'] = `Bearer ${token}`;
//           }
//           config.headers['Content-Type'] = 'application/json';
//           return config;
//         },
//         (error) => {
//           console.error('Request Interceptor Error:', error); // Log request error
//           return Promise.reject(error);
//         }
//       );
      
//       axiosInterceptor.interceptors.response.use(
//         (response) => {
//           console.log('Response Interceptor: Response', response); // Log response
//           return response;
//         },
//         async (error) => {
//           console.log('Response Interceptor Error:', error.status); // Log response error
//           if (error.response && error.status===403 ) {
//             console.log('Calling the refresh token API...');
//             const {data,status}=await refreshToekenRequestAPI();
//             console.log("called refresh token ",data)
//             if(status===200)
//             {
//       console.log("data from token api",data);
            
//             if(data.accessToken){
//                 let newToken;
//                 const tokens =  localStorage.getItem('tokens');
//                 if (tokens) {
//                     newToken = await  JSON.parse(tokens);
//                 }
//                 console.log("called refresh token ",data)
      
//                 localStorage.setItem('tokens',JSON.stringify({...newToken,accesstoken:data.accessToken}));}
//                 dispatch(updateTokens(data))
//             // Add refresh token logic here
//             const originalRequest = error.config;
      
            
//             if (data.accessToken) {
              
//               originalRequest.headers.authorization = `Bearer ${data.accessToken}`;
//               originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;

//               console.log(data.accessToken,"accessTokenSSS",originalRequest);
//               originalRequest.headers['Content-Type'] = 'application/json';
//               // Retry the original request with the new token
//               return axios(originalRequest);
//             }
//             }
//             else{
      
//           }
            
//           }
      
//           // window.location.replace(`${process.env.REACT_APP_FRONTEND_URL}auth/signupuser`);
        
//           // window.location.href = `${process.env.REACT_APP_FRONTEND_URL}auth/signupuser`;
       
//           return Promise.reject(error);
//         }
//       );
// }
async function getTokens(params) {
try {
const res=await getValidateRefreshTokenAPI(authTokens.refreshtoken);

if(res)
{
  console.log("aaaa",res,"res");
   localStorage.setItem('tokens',JSON.stringify({accesstoken:res.data.accessToken,refreshtoken:res.data.refreshToken}))
  dispatch(updateTokens(res.data))
}
} catch (error) {
console.log(error);
}
finally{

}
 

  
}
useEffect(()=>{
// if(authTokens){
  console.log("inside here");
  if(authTokens)
  {

    getTokens()
  }
  else{
    dispatch(hideLoader())
  }
},[])

useEffect(()=>{
if(authTokens)
{
try {
  // const res=await getCartItemsAPI();
  // if(res){
  //   console.log(res.data,"cartDara");
  //   dispatch(hydrateCart(res.data))
  // }
  dispatch(fetchCart())
} catch (error) {
  console.log(error,"error");
}

finally{
  dispatch(hideLoader())
}


}},[authTokens])


    return(
        <div>
            {globalLoader?<div  className='w-[100vw] h-[100vh] flex justify-center items-center '> <Loader/></div>:children}
        </div>
    )
}
