import React, { useEffect, useState } from "react";
import "./OnSearch.css";
import { useParams } from "react-router-dom";
import OnSearchRight from "../../Components/OnSearchRight/OnSearchRight";
import OnSearchLeft from "../../Components/OnSearchLeft/OnSearchLeft";
import { searchbarRequestedDataAPI } from "../../api";

const OnSearch = () => {
  const { id } = useParams();
  const myset = new Set();
  const [product_data, setproduct_data] = useState(null);
  const [brandsdata, setbrandsdata] = useState(null);
  const [brandDataFilter, setbrandDataFilter] = useState(null);
  const [filteredData, setfilteredData] = useState(product_data);

  const fetchdata = async (id) => {
   try {
     const {data}=await searchbarRequestedDataAPI({productId:id})
     setproduct_data(data);
     setfilteredData(data);
     data.map((item) => {
       myset.add(item.brand.toUpperCase());
       return null;
     });
     setbrandsdata(Array.from(myset));
     console.log(myset);
     console.log(brandsdata);
     
   } catch (error) {
    console.log(error)
   }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchdata(id);
  }, [id]);
  useEffect(() => {
    if (brandDataFilter !== null) {
      setfilteredData(
        product_data.filter((item) => item.brand === brandDataFilter)
      );
    }
  }, [brandDataFilter]);

  return (
    <div>
      <div className="w-[100%] h-[100%] mt-[8rem]   ">
        <div className="w-[100%] h-[100%] max-w-screen-2xl mx-auto flex flex-col justify-start items-center min-h-fit  ">
          <div className="OnSearchOuter Vflex AroundFlex">
            <div className="OnSearchInnerLeft">
              <OnSearchLeft
                data={brandsdata}
                filterFunction={setbrandDataFilter}
              />
            </div>
            <div className="OnSearchInnerRight">
              <OnSearchRight data={filteredData} />
              {/* {console.log("filteredData",filteredData)} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnSearch;