import { createSlice } from "@reduxjs/toolkit";

const globalLoaderSlice=createSlice({
    name:'global loader',
    initialState:true,
    reducers:{
        showLoader:(state,action)=>{
           return  true;
        },
        hideLoader:(state,action)=>{
            return false;
        }
    }
}
)
export const {showLoader,hideLoader}=globalLoaderSlice.actions;
export default globalLoaderSlice.reducer;